//importar fontes
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

//importar variables
@import 'variables';

//importar mixins
@import 'mixins';


//importar componentes
@import './components/header';
@import './components/slide';
@import './components/cards-dock';
@import './components/footer';
@import './components/product-card';
@import './components/form';

//importar pages
@import './pages/contato';
@import './pages/nossos-produtos';



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root{
  width: 100%;
  height: 100%;
  font-family: 'Raleway', sans-serif;
}

a{
  color: inherit;
  &:hover{
    color: inherit;
    text-decoration: none;
  }
}

.App{
  background-color: rgb(28, 28, 28);
  background-image: url('/assets/bg.png');
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;

  #navbar-space{
    height: 128px;
  }

  h1{
    font-size: 70px;
  }
  h2{
    font-size:  36px;
  }
  p{
    font-size: 22px;
    // text-indent: 1em;
    // &.indent-0{
    //   text-indent: 0;
    // }
    &.link-green{
      &:hover{
        color: $green_1;
      }
    }
  }

}
.content-container{
  max-width: 100%;
  margin: 4% 10%;
  padding: 3% 5%;
  background: #FFF;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px){
    margin: 6% 8%;
  }

  p{
    text-indent: 1em;
  }

  img{
    width: 60%;
    align-self: center;
    margin: 30px 0;
  }

}

//importar responsividade
@import 'responsive';





