.carousel{
  max-height: calc(100vh - 119px);
  overflow: hidden;
  display: flex;
  align-items: center;

  .carousel-indicators{
    li{
      height: 10px;
      width: 10px;
      border-top: none;
      border-bottom: none;
      border-radius: 10px;
      margin-bottom: 1.5%;
    }
  }

  &.nossa-fazenda{
    max-width: 80%;
    margin: 20px auto 40px;
    img{
      margin: 0;
    }
  }

  .carousel-caption{
    @include mp0;
    height: 100%;
    padding-top: 20px; //necessário?
    display: flex;
    flex-direction: column;
    justify-content: center;

    #home-1{}
    #home-2{
      background: rgba(255, 255, 255, 0.6);
      color: #222;
      padding: 20px 10px; 
    }
    #home-3{
        background: rgb(36, 23, 0);
        color: #FFF;
        padding: 10px; 
        border-radius: 10px;
        width: fit-content;
        margin: 0 auto;

        @media screen and (max-width: 768px){
          transform: scale(0.8);
        }
    }

  }
}