.navbar{
  background: $background-grey;
  padding: 30px 7% 20px;
  justify-content: space-between;
  min-height: 120px;
  align-items: flex-start;

  .navbar-brand{
    @include mp0;
    width: min-content;
    img {
      width: 13%;
      min-width: 150px;
    }
  }

  .navbar-toggler{
    margin-top: 10px;
  }

  .navbar-collapse{
    margin-left: 30px;
    align-self: center;
    .navbar-nav{
      margin-right: 0 !important;
      margin-left: auto;

      .nav-link{
        // width: fit-content;
        display: flex;
        font-size: 20px;
        margin: 0 auto; 
        color: $grey_1;
        transition: 0.3s;
        padding: 0;
        align-items: center;
        
        a{
          padding: 10px;
          text-align: center;
        }

        &:hover{
          color: #FFF;
        }
        &:focus{
          color: $grey_2;
        }
      }
    }
  }
}