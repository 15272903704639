.horizontal-card{
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  .card-body{
    width: 115%;

    .card-title{
      font-size: 30px;
      font-weight: 500;
    }
    .card-subtitle{
      font-size: 20px;
    }
    .card-text{
      margin-top: 20px;
      p{
        font-size: 18px;
      }
    }
  }
  .carousel{
    width: 75%;
    img{
      margin: 0;
    }
  }
}