.content-container.contato {
  #contato-img {
    max-width: 250px;
  }

  .btn-success{
    margin: 5px 0;
    @media screen and (max-width: 600px){
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
