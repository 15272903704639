@mixin mp0 {
	margin: 0;
	padding: 0;
}
@mixin center_x {
	left: 50%;
	transform: translateX(-50%);
}
@mixin center_y {
	top: 50%;
	transform: translateY(-50%);
}
@mixin center_x_y {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}