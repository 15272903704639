form{
  .form-group{
    .form-control{
      &:valid{
        background-image: none;
      }
      &:invalid{
        background-image: none;
      }
    }
  }
}