.footer{
  background: $background-grey;
  // height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
  padding-top: 5px;

  .footer-content{
    margin: 12px 0;
    .social-media-icons{
      display: flex;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;
      justify-content: space-between;

      a{
        color: $green_1;
      }

      i{
        height: fit-content;
        margin: 0 !important;
      }
    }

    .copyright{
      text-align: center;
      color: $grey_1;
      p {
        margin: 0;
        font-size: 20px;

      }

      a{
        margin-left: 5px;
        color: $green_1;
      }
    }
  }
}
