@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
.navbar {
  background: #343a40;
  padding: 30px 7% 20px;
  justify-content: space-between;
  min-height: 120px;
  align-items: flex-start; }
  .navbar .navbar-brand {
    margin: 0;
    padding: 0;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content; }
    .navbar .navbar-brand img {
      width: 13%;
      min-width: 150px; }
  .navbar .navbar-toggler {
    margin-top: 10px; }
  .navbar .navbar-collapse {
    margin-left: 30px;
    align-self: center; }
    .navbar .navbar-collapse .navbar-nav {
      margin-right: 0 !important;
      margin-left: auto; }
      .navbar .navbar-collapse .navbar-nav .nav-link {
        display: flex;
        font-size: 20px;
        margin: 0 auto;
        color: #888;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        padding: 0;
        align-items: center; }
        .navbar .navbar-collapse .navbar-nav .nav-link a {
          padding: 10px;
          text-align: center; }
        .navbar .navbar-collapse .navbar-nav .nav-link:hover {
          color: #FFF; }
        .navbar .navbar-collapse .navbar-nav .nav-link:focus {
          color: #AAA; }

.carousel {
  max-height: calc(100vh - 119px);
  overflow: hidden;
  display: flex;
  align-items: center; }
  .carousel .carousel-indicators li {
    height: 10px;
    width: 10px;
    border-top: none;
    border-bottom: none;
    border-radius: 10px;
    margin-bottom: 1.5%; }
  .carousel.nossa-fazenda {
    max-width: 80%;
    margin: 20px auto 40px; }
    .carousel.nossa-fazenda img {
      margin: 0; }
  .carousel .carousel-caption {
    margin: 0;
    padding: 0;
    height: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .carousel .carousel-caption #home-2 {
      background: rgba(255, 255, 255, 0.6);
      color: #222;
      padding: 20px 10px; }
    .carousel .carousel-caption #home-3 {
      background: #241700;
      color: #FFF;
      padding: 10px;
      border-radius: 10px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        .carousel .carousel-caption #home-3 {
          -webkit-transform: scale(0.8);
                  transform: scale(0.8); } }

.cards-dock {
  display: flex;
  padding: 0 9%;
  justify-content: space-between; }
  .cards-dock .card {
    width: 100%;
    border: none;
    margin: 1%;
    border-radius: 0.50rem; }
    .cards-dock .card .card-img-top {
      border-top-left-radius: calc(0.50rem - 1px);
      border-top-right-radius: calc(0.50rem - 1px); }
    .cards-dock .card .card-title {
      font-size: 28px; }
    .cards-dock .card .card-body {
      display: flex;
      flex-flow: column;
      padding: 1.50rem; }
      .cards-dock .card .card-body .btn {
        margin: auto 0 0 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }

.footer {
  background: #343a40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
  padding-top: 5px; }
  .footer .footer-content {
    margin: 12px 0; }
    .footer .footer-content .social-media-icons {
      display: flex;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;
      justify-content: space-between; }
      .footer .footer-content .social-media-icons a {
        color: #55A929; }
      .footer .footer-content .social-media-icons i {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0 !important; }
    .footer .footer-content .copyright {
      text-align: center;
      color: #888; }
      .footer .footer-content .copyright p {
        margin: 0;
        font-size: 20px; }
      .footer .footer-content .copyright a {
        margin-left: 5px;
        color: #55A929; }

.horizontal-card {
  display: flex;
  flex-direction: row;
  margin: 10px 0; }
  .horizontal-card .card-body {
    width: 115%; }
    .horizontal-card .card-body .card-title {
      font-size: 30px;
      font-weight: 500; }
    .horizontal-card .card-body .card-subtitle {
      font-size: 20px; }
    .horizontal-card .card-body .card-text {
      margin-top: 20px; }
      .horizontal-card .card-body .card-text p {
        font-size: 18px; }
  .horizontal-card .carousel {
    width: 75%; }
    .horizontal-card .carousel img {
      margin: 0; }

form .form-group .form-control:valid {
  background-image: none; }

form .form-group .form-control:invalid {
  background-image: none; }

.content-container.contato #contato-img {
  max-width: 250px; }

.content-container.contato .btn-success {
  margin: 5px 0; }
  @media screen and (max-width: 600px) {
    .content-container.contato .btn-success {
      width: 100%;
      margin-bottom: 8px; } }

.page-nossos-produtos h1 {
  margin-bottom: 20px; }

.page-nossos-produtos p {
  text-indent: 0 !important; }

.page-nossos-produtos #ficou-interessado {
  border: solid 1px #ddd;
  border-radius: 2px;
  padding: 20px;
  margin-top: 10px; }
  .page-nossos-produtos #ficou-interessado span {
    font-size: 18px; }

.page-nossos-produtos #contato-btn {
  margin-top: 20px; }
  @media screen and (max-width: 600px) {
    .page-nossos-produtos #contato-btn {
      width: 100%; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body, #root {
  width: 100%;
  height: 100%;
  font-family: 'Raleway', sans-serif; }

a {
  color: inherit; }
  a:hover {
    color: inherit;
    text-decoration: none; }

.App {
  background-color: #1c1c1c;
  background-image: url("/assets/bg.png");
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%; }
  .App #navbar-space {
    height: 128px; }
  .App h1 {
    font-size: 70px; }
  .App h2 {
    font-size: 36px; }
  .App p {
    font-size: 22px; }
    .App p.link-green:hover {
      color: #55A929; }

.content-container {
  max-width: 100%;
  margin: 4% 10%;
  padding: 3% 5%;
  background: #FFF;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 768px) {
    .content-container {
      margin: 6% 8%; } }
  .content-container p {
    text-indent: 1em; }
  .content-container img {
    width: 60%;
    align-self: center;
    margin: 30px 0; }

@media screen and (max-width: 768px) {
  .App #logo-header {
    min-width: 100px; }
  .App #navbar-space {
    height: 120px; }
  .App h1 {
    font-size: 42px; }
  .App h2 {
    font-size: 22px; }
  .App p {
    font-size: 18px; }
  .carousel {
    max-height: calc(100vh - 92.4px); }
    .carousel.nossa-fazenda {
      max-width: 100%; }
    .carousel .carousel-caption {
      padding-top: 70px; }
  .cards-dock {
    flex-flow: column;
    align-items: center;
    padding: 0 10%; }
    .cards-dock .card {
      margin: 0;
      margin-bottom: 30px; }
  .horizontal-card {
    flex-direction: column-reverse; }
    .horizontal-card .card-body {
      width: 100%; }
    .horizontal-card .carousel {
      width: 100%; }
  .social-media-icons {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); } }

