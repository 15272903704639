.page-nossos-produtos{
  h1{
    margin-bottom: 20px;
  }
  p{
    text-indent: 0 !important;
  }
  #ficou-interessado{
    border: solid 1px #ddd;
    border-radius: 2px;
    padding: 20px;
    margin-top: 10px;

    span{
      font-size: 18px;
    }
  }
  #contato-btn{
    margin-top: 20px;

    @media screen and (max-width: 600px){
      width: 100%;
    }
  }
}
