.cards-dock{
  display: flex;
  padding: 0 9%;
  justify-content: space-between;
  
  .card{
    width: 100%;
    border: none;
    margin: 1%;
    border-radius: 0.50rem;

    .card-img-top{
      border-top-left-radius: calc(0.50rem - 1px);
      border-top-right-radius: calc(0.50rem - 1px);
    }

    .card-title{
      font-size: 28px;
    }

    .card-body{
      display: flex;
      flex-flow: column;
      padding: 1.50rem;
      
      .btn{
        margin: auto 0 0 0;  
        width: fit-content;
        //essas cores são necessárias?
        // background-color: $green_1;
        // border-color: $green_1;
      }
    }
  }

}