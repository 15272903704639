@media screen and (max-width: 768px) {

  .App{

    #logo-header{
      min-width: 100px;
    }
    #navbar-space{
      height: 120px;
    }
    h1{
      font-size: 42px;
    }
    h2{
      font-size:  22px;
    }
    p{
      font-size: 18px;
    }
  }
  .carousel{
    max-height: calc(100vh - 92.4px);
    &.nossa-fazenda{
      max-width: 100%;
    }
    .carousel-caption{
      padding-top: 70px;
    }
  }
  .cards-dock{
    flex-flow: column;
    align-items: center;
    padding: 0 10%;

    .card{
      margin: 0;
      margin-bottom: 30px;
    }
  }
  .horizontal-card{
    flex-direction: column-reverse;
    .card-body{
      width: 100%;
    }
    .carousel{
      width: 100%;
    }
  }

  .social-media-icons{
    transform: scale(0.9);
  }
}
